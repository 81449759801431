import React, { lazy } from "react";
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Events = lazy(() =>
  import("./pages/Events")
);
const Products = lazy(() => import("./pages/Products"));
const Projects = lazy(() => import("./pages/Projects"));
const Whatswedo = lazy(() => import("./pages/Whatswedo"));

const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    element: Home,
  },
  //USER ROUTES
  {
    path: "/about",
    exact: true,
    name: "About",
    element: About,
  },
  {
    path: "/contact",
    exact: true,
    name: "Contact",
    element: Contact,
  },
  {
    path: "/events",
    exact: true,
    name: "Events",
    element: Events,
  },
  {
    path: "/products",
    exact: true,
    name: "Products",
    element: Products,
  },
  {
    path: "/projects",
    exact: true,
    name: "Projects",
    element: Projects,
  },
  {
    path: "/whats-we-do",
    exact: true,
    name: "Whatswedo",
    element: Whatswedo,
  },
];

export default routes;
