import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import AppContent from "./AppContent";
import Header from "./Header";
import Footer from "./Footer";

const ProtectedLayout = () => {
  const { user, logout } = useAuth();

  // if (!user) {
  //   return <Navigate to="/login" />;
  // }

  return (
    <>
      <Header />
      <AppContent />
      <Footer />
    </>
  );
};

export default React.memo(ProtectedLayout);
