import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Button, Drawer } from "rsuite";
import { FaBars } from "react-icons/fa";

function Header() {
  const [expand, setExpand] = useState(false);
  const [mobile, setMobile] = useState(false);
  const navigate = useNavigate();
  const handleResize = () => {
    if (window.innerWidth < 920) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  // create an event listener
  useEffect(() => {
    if (window.innerWidth < 920) {
      setMobile(true);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Drawer placement={"left"} open={expand} onClose={() => setExpand(false)}>
        <Drawer.Header>
          <Drawer.Title>
            <Link to="/">
              {" "}
              <img src="/images/logo.png"></img>
            </Link>
          </Drawer.Title>
          <Drawer.Actions></Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <ul className="p-0">
            <li>
              <Link to="/"> Home</Link>
            </li>
            <li>
              <Link to="/about">About us</Link>
            </li>
            <li>
              <Link to="/whats-we-do">What We Do</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
          </ul>
        </Drawer.Body>
      </Drawer>
      <div className="mobile">
        <div className="top-header-mobile">
          <div className="logo">
            <Link to="/">
              {" "}
              <img src="/images/logo.png"></img>
            </Link>
          </div>
          <div>
            {" "}
            <div className="mobile-nav-btn">
              <FaBars onClick={() => setExpand(true)} />
            </div>
          </div>
        </div>
      </div>
      {!mobile && (
        <div className="top-header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-3">
                <div className="logo">
                  <Link to="/">
                    {" "}
                    <img
                      src="/images/logo.png"
                      style={{ maxWidth: "40%" }}
                    ></img>
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="nav-links">
                  <ul className="d-flex justify-content-center m-0">
                    <li>
                      <Link to="/"> Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                    <li>
                      <Link to="/whats-we-do">What We Do</Link>
                    </li>
                    <li>
                      <Link to="/products">Products</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="header-btn text-end">
                  <button type="button" class="btn btn-dark">
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
